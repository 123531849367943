import React from "react";
import { SocialIcon } from "react-social-icons";

export default function Socials({ settings }) {
  const socials = ["facebook", "whatsapp", "linkedin", "twitter", "instagram"];
  return (
    <div className="socials">
      {socials.map((logo) => {
        if (!settings[logo] || settings[logo] === "") {
          return <div key={logo}></div>;
        }
        return (
          <SocialIcon
            network={logo}
            url={settings[logo]}
            style={{ width: 32, height: 32, padding: 0 }}
            key={logo}
          />
        );
      })}
    </div>
  );
}
