import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.footer`
  background: #3a3a3a;
  background-color: ${props => props.theme.footerBackgroundColor};
  border-bottom: 3px solid ${props => props.theme.primaryColor};

  .inner {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    color: #fff;
    color: ${props => props.theme.footerTextColor};
    ${MEDIA.TABLET`
      display: block;
    `};
  }

  a {
    color: ${props => props.theme.footerTextColor};
    text-decoration: none;
    padding: 4px 0;
    position: relative;
    &:before {
      display: none;

    }

  }
`;

export const Menus = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  ul > li > a {
    color: #000 !important;
  }
  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const Column = styled.div`
  flex: 1 1;
  padding: 16px 8px;
  ${MEDIA.TABLET`
    display: block;
  `};
  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  h3 {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 0 0 8px;
  }
  ul.skilist {
    column-count: 4;
    padding: 0 0 16px;
    ${MEDIA.TABLET`
           column-count: 1;
  `};
  }
  .socials {
    display: flex;
    a {
      margin: 0 8px 0 0;
    }
  }
  ul {
    li {
      padding: 8px 0;
      a {
        text-decoration: none;
        position: relative;
        font-weight: 500;
        transition: all 0.3s ease;
        &:before {
          position: absolute;
          width: 10px;
          height: 1px;
          bottom: 0;
          left: 0;
          content: '';
          background: #000;
        }
        &:hover {
          color: ${props => props.theme.primaryColor};
        }
      }
    }
  }
`;

export const Copyright = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  background: #fff;
  padding: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: green;
  svg {
    padding-right: 4px;
  }
`;

export const ContactColumn = styled.div`
  li {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      color: ${props => props.theme.primaryColor};
      &:before {
        display: none;
      }
      svg {
        margin-right: 8px;
        path {
          fill: ${props => props.theme.primaryColor};
        }
      }
    }
  }
`;

export const RegionsContainer = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px 32px;
  border-top: 1px solid ${props => props.theme.primaryColor};
  h3 {
    font-weight: 700;
  }
  ul {
    columns: 100px 4;
    li {
      padding: 4px 0;
      a {
        color: #000;
      }
    }
  }
`