import React from 'react';
import LocaleMenu from './LocaleMenu';
import { TopNavContainer } from './TopNav.css';

function TopNav({ settings, locale }) {
  return (
    <TopNavContainer>
      {settings.topNavText && (
        <div dangerouslySetInnerHTML={{ __html: settings.topNavText[locale] }}></div>
      )}
      <LocaleMenu {...settings} currentLocale={locale}/>
    </TopNavContainer>
  )
}

export default TopNav