import React from "react";
import styled from "styled-components";
import DeIcon from "../../icons/locales/de.svg";
import EnIcon from "../../icons/locales/en.svg";
import NlIcon from "../../icons/locales/nl.svg";

function localeIcon(key) {
  const iconSet = {
    height: "16px",
    style: { paddingRight: "8px" },
  };
  let flag;
  switch (key) {
    case "nl":
      flag = <NlIcon {...iconSet} />;
      break;
    case "de":
      flag = <DeIcon {...iconSet} />;
      break;
    case "en":
      flag = <EnIcon {...iconSet} />;
      break;
    default:
      break;
  }
  return flag;
}

function LocaleMenu({ default_locale, locales, currentLocale }) {
  const flags = locales.map((loc) => {
    const url = loc === default_locale ? "/" : "/" + loc;

    return {
      lang: loc,
      url,
    };
  });
  return (
    <Container>
      {flags.length > 1 && flags.map((flag) => {
        if (flag.lang == currentLocale) {
          return null;
        }
        return (
          <li key={flag.lang}>
            <a href={flag.url}>
              {localeIcon(flag.lang)}
              {flag.lang.toUpperCase()}
            </a>
          </li>
        );
      })}
    </Container>
  );
}

export const Container = styled.ul`
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  margin-left: 16px;
  a {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0 8px !important;
    &:before {
      display: none;
    }
  }
`;

export default LocaleMenu;
