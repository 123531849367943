import styled from 'styled-components';
// import { mainColor } from 'constants/theme';
// import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.ul`
  display: flex;
  list-style: none;
  padding: 16px 0 !important;
  justify-content: flex-start;
  align-items: center;
  a {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0 8px !important;
    &:before {
      display: none;
    }
  }
`;
