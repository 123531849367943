import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Location } from "@reach/router";
import schemaGenerator from "../../helpers/schemaGenerator";

const HeadCont = ({
  siteTitle,
  siteDescription,
  siteUrl,
  pageTitle,
  pageDescription,
  metaDescription = pageDescription ? pageDescription : siteDescription,
  pageTitleFull = pageTitle ? `${pageTitle} | ${siteTitle}` : siteTitle,
  themeColor,
  imageUrl,
  location,
  locale,
  canonical = siteUrl + (location.pathname || ""),
  hrefLangs
}) => (
  <Helmet>
    <html lang={locale} />
    <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
    <meta
      content="width=device-width,initial-scale=1.0,user-scalable=yes"
      name="viewport"
    />
    <meta content={siteTitle} name="apple-mobile-web-app-title" />
    <meta content={pageTitleFull} property="og:title" />
    <meta content={pageTitleFull} name="twitter:title" />
    <title>{pageTitleFull}</title>
    <meta content={metaDescription} name="description" />
    <meta content={metaDescription} property="og:description" />
    <meta content={metaDescription} name="twitter:description" />
    <meta content="yes" name="apple-mobile-web-app-capable" />
    <meta
      content="black-translucent"
      name="apple-mobile-web-app-status-bar-style"
    />
    <meta content={themeColor} name="theme-color" />
    <meta content={siteTitle} name="application-name" />
    <meta content="website" property="og:type" />
    <meta content={siteTitle} property="og:site_name" />
    <meta content="summary_large_image" name="twitter:card" />
    <meta content={pageTitleFull} name="twitter:text:title" />
    <meta content={canonical} property="og:url" />
    <meta content={canonical} name="twitter:url" />
    <link rel="canonical" href={canonical} />
    <meta content={imageUrl || `${siteUrl}/social.png`} property="og:image" />
    <meta content="1024" property="og:image:width" />
    <meta content="512" property="og:image:height" />
    <meta content={imageUrl || `${siteUrl}/social.png`} name="twitter:image" />
    <meta content="1024" name="twitter:image:width" />
    <meta content="512" name="twitter:image:height" />
    <meta content={imageUrl || `${siteUrl}/social.png`} property="og:image" />
    <meta content="1024" property="og:image:width" />
    <meta content="512" property="og:image:height" />
    <meta content={themeColor} name="msapplication-TileColor" />
    <link rel="icon" href="/favicon.ico" type="image/x-icon" />{" "}
    <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
    <link
      rel="stylesheet"
      href="https://portal.bukazu.com/main.css"
      crossorigin=""
    />
    {hrefLangs.map(lang => (
      <link href={lang.path} hreflang={lang.lang} />
    ))}
    <script type="application/ld+json">
      {JSON.stringify(
        schemaGenerator({
          location,
          canonical,
          siteUrl,
          pageTitle,
          siteTitle,
          pageTitleFull,
        })
      )}
    </script>
  </Helmet>
);

HeadCont.propTypes = {
  siteTitle: PropTypes.string,
  siteTitleShort: PropTypes.string,
  siteDescription: PropTypes.string,
  siteUrl: PropTypes.string,
  themeColor: PropTypes.string,
  social: PropTypes.objectOf(PropTypes.string),
  imageUrl: PropTypes.string,
  canonical: PropTypes.string,
  pageTitle: PropTypes.string,
  pageTitleFull: PropTypes.string,
};

const Head = (props) => (
  <StaticQuery
    query={graphql`
      query {
        buroBorkSite {
          id
          field
        }
        site {
          siteMetadata {
            siteTitle
            siteUrl
          }
        }
      }
    `}
    render={(data) => {
      const site = JSON.parse(data.buroBorkSite.field);
      const { subtitle, title, url, logo } = site.site_settings;

      let imageUrl = null
      if (logo) {
        imageUrl = `https://cdn.burobork.nl/${logo[0].reference}`
      }

      return (
        <Location>
          {({ location }) => (
            <HeadCont
              {...data.site.siteMetadata}
              themeColor={site.site_settings.primaryColor}
              siteDescription={subtitle}
              siteTitle={title}
              siteUrl={url}
              imageUrl={imageUrl}
              {...props}
              location={location}
            />
          )}
        </Location>
      );
    }}
  />
);

Head.defaultProps = {
  hrefLangs: []
}

export default Head;
