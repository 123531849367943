import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { NavContainer } from "./Header.css";
import NavItems from "./NavItems";

const Nav = ({ locale }) => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        allBuroBorkMenu(filter: { name: { eq: "Primary" } }) {
          edges {
            node {
              id
              field
              name
              locale
            }
          }
        }
      }
    `}
    render={(data) => {
      let menu = data.allBuroBorkMenu.edges.find(
        (edge) => edge.node.locale === locale
      );

      return (
        <NavContainer>
          {menu && <NavItems items={menu.node.field} />}
        </NavContainer>
      );
    }}
  />
);

Nav.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Nav;
