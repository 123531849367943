import React from 'react';
import { Link } from 'gatsby';
import ChevronDown from '../../icons/chevronDown.svg';

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: 'active' } : null;
};

const NavItems = ({ items }) => {
  items = JSON.parse(items);

  function sub_menu(items) {
    if (items.length > 0) {
      return (
        <ul className="sub-menu">
          {items.map(function(item) {
            return (
              <li key={item.id}>
                <Link to={item.url} getProps={isActive}>
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const navs = items.menu_items.map(function(item) {
    let val;

    let subs = items.menu_items.filter(x => x.parent === item.id);
    let myClass = '';
    if (subs.length > 0) {
      myClass = 'parent';
    }
    if (subs.length > 0) {
      val = (
        <li key={item.title} className={myClass}>
          <div className="parent-link" >
            {item.title}
            <ChevronDown width="16px" />
          </div>
          {sub_menu(subs)}
        </li>
      );
    } else {
      val = (
        <li key={item.title} className={myClass}>
          <Link to={item.url} getProps={isActive}>
            {item.title}
          </Link>
        </li>
      );
    }

    if (item.parent) {
      return '';
    } else {
      return val;
    }
  });

  return <ul>{navs}</ul>;
};

export default NavItems;
