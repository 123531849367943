import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import { PageContainer } from "./Layout.css";
import Head from "./Head";

import Global from "../../styles/reset.css";
import "../../styles/typography.css";
import { ThemeProvider } from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { IntlProvider } from "react-intl";

import en from '../../locales/en/translations.json';
import nl from '../../locales/nl/translations.json';
import de from '../../locales/de/translations.json';
import Footer from "../Footer";

const messages = { en, nl, de };


function Layout({ data, children, locale }) {
  const { site_settings } = data;

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <PageContainer>
        <Head />
        <Global />
        <Header data={data} locale={locale} />
        {children}
        <Footer locale={locale}></Footer>
      </PageContainer>
    </IntlProvider>
  );
}

const LayoutWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
        buroBorkSite {
          id
          field
        }
      }
    `}
    render={(data) => {
      const parsedData = JSON.parse(data.buroBorkSite.field);
      const secondaryColor =
        parsedData.site_settings.secondaryColor ||
        parsedData.site_settings.primaryColor;
      const theme = {
        primaryColor: parsedData.site_settings.primaryColor,
        secondaryColor,
        footerBackgroundColor: parsedData.site_settings.footerBackgroundColor,
        footerTextColor: parsedData.site_settings.footerTextColor,
        backgroundHeader: parsedData.site_settings.backgroundHeader,
        topNavColor: parsedData.site_settings.topNavColor,
        topNavBackground: parsedData.site_settings.topNavBackground,
      };

      return (
        <ThemeProvider theme={theme}>
          <Layout data={parsedData} {...props} />
        </ThemeProvider>
      );
    }}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
