import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Logo from "../../icons/logo.svg";
import { Container, Column, Copyright } from "./footer.css";
import LocaleMenu from "./LocaleMenu";
import { FormattedMessage } from "react-intl";
import Socials from "./Socials ";
import Regions from "./Regions";

const Footer = ({ locale }) => (
  <>
    <StaticQuery
      query={graphql`
        query {
          buroBorkSite {
            id
            field
          }
        }
      `}
      render={(data) => {
        const { site_settings } = JSON.parse(data.buroBorkSite.field);
        const {  showRegions, default_locale } = site_settings;

        return (
          <>
            {showRegions && showRegions === true && (
              <Regions locale={locale} default_locale={default_locale} />
            )}
            <Container>
              <div className="inner">
                <Column>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: site_settings.footerColumn1?.[locale],
                    }}
                  />
                </Column>
                <Column>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: site_settings.footerColumn2?.[locale],
                    }}
                  />
                  <Socials settings={site_settings} />
                </Column>
                <Column
                  dangerouslySetInnerHTML={{
                    __html: site_settings.footerColumn3?.[locale],
                  }}
                />
                <Column>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: site_settings.footerColumn4?.[locale],
                    }}
                  ></div>
                  <LocaleMenu {...site_settings} />
                </Column>
              </div>
            </Container>
          </>
        );
      }}
    />
    <Copyright>
      kikkerenvos.nl
    </Copyright>
  </>
);

export default Footer;
