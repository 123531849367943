import React, { useState } from "react";
import TopNav from "../TopNav";
import { Container, Navigation, Wrapper } from "./Header.css";
import Logo from "./Logo";
import Nav from "./Nav";

function Header({ locale, data }) {
  const [menuVisible, setMenuVisible] = useState(false)
  const { site_settings } = data
  return (
    <Wrapper>


    <Container topNav={site_settings.topNav}>
      <Logo data={data} locale={locale} />

      <Navigation className="navigation" show={menuVisible}>
        {site_settings.topNav && (
          <TopNav settings={site_settings} locale={locale}>
          </TopNav>
        )}
        <Nav locale={locale} />
      </Navigation>

      <div className="navigation-toggle" onClick={() => setMenuVisible(!menuVisible)} onKeyPress={() => setMenuVisible(!menuVisible)} role="button" tabIndex="-1">
        <svg
          width="32"
          height="29"
          viewBox="0 0 64 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M59.8 48.8L20.2 48.8C17.9 48.8 16 50.7 16 53C16 55.3 17.9 57.2 20.2 57.2L59.8 57.2C62.1 57.2 64 55.3 64 53C64 50.7 62.1 48.8 59.8 48.8Z"
            fill="black"
          />
          <path
            d="M4.2 32.7999L59.8 32.7999C62.1 32.7999 64 30.8999 64 28.5999C64 26.2999 62.1 24.3999 59.8 24.3999L4.2 24.3999C1.9 24.3999 0 26.2999 0 28.5999C0 30.8999 1.9 32.7999 4.2 32.7999Z"
            fill="black"
          />
          <path
            d="M20.2 8.3999L59.8 8.3999C62.1 8.3999 64 6.4999 64 4.1999C64 1.8999 62.1 -9.91821e-05 59.8 -9.91821e-05L20.2 -9.91821e-05C17.9 -9.91821e-05 16 1.8999 16 4.1999C16 6.4999 17.9 8.3999 20.2 8.3999Z"
            fill="black"
          />
        </svg>
      </div>
    </Container>
            </Wrapper>
  );
}

export default Header;
