import styled from "styled-components";
import MEDIA from "../../helpers/mediaTemplates";

export const Wrapper = styled.header`
  background-color: ${(props) => props.theme.backgroundHeader ? props.theme.backgroundHeader : '#fff'};
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: ${props => props.topNav ? '120px' : '100px'} ;
  background: #fff;
  background-color: ${(props) => props.theme.backgroundHeader ? props.theme.backgroundHeader : '#fff'};
  z-index: 9999;
  max-width: 1600px;
  margin: 0 auto;

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
  .logo {
    max-height: 100%;
    display: flex;
    align-items: center;
    padding: 4px;
    font-size: 2.2rem;
    font-weight: 700;
    box-sizing: border-box;
    &-img {
      height: 110px;
    }
    a {
      color: ${(props) => props.theme.primaryColor};
    }
  }
  .navigation-toggle {
    display: none;
  }
  ${MEDIA.TABLET`
  height: 80px;
  .logo {
    max-height: 80px
    height: 80px;
    &-img {
      height: 68px;
    }
  }
    ${(props) =>
      props.show
        ? `
      position: fixed;
      width: 100%;
      background-color: ${(props) => props.theme.backgroundHeader ? props.theme.backgroundHeader : '#fff'};
      z-index: 9999;
    `
        : ""}
    .navigation-toggle {
      margin-left: auto;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 8px 24px;
      background-color: ${(props) => props.theme.backgroundHeader ? props.theme.backgroundHeader : '#fff'};
      svg {
        width: 24px;
      }
    }
  `};
`;

export const Navigation = styled.div`
  flex: 2;
  margin: 0 32px;
  background-color: ${(props) => props.theme.backgroundHeader ? props.theme.backgroundHeader : '#fff'};
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  ${MEDIA.TABLET`
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 9999;
    background-color: ${(props) => props.theme.backgroundHeader ? props.theme.backgroundHeader : '#fff'};
    width: 0;
    opacity: 0;
    transition: all 0.4s ease;
    right: -500px;
    top: 60px;
    height: 100%;
    ${(props) =>
      props.show
        ? `
      top: 80px;
      width: 100%;
      height: 100vh;
      margin: 0;
      right: 0;
      opacity: 1;
      nav {
        padding: 16px;
        order: 1;
      }
      .topnav {
        padding: 16px;
        order: 2;
        border: 0;
      }
      ul {
        flex-direction: column;
        li {
          width: 100%;
          margin: 0 !important;
        }
      }
    `
        : ""}
  `}
`;

export const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  ${MEDIA.TABLET`
    justify-content: center;
    height: auto;
  `}

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    height: 100%;

    li {
      position: relative;
      font-size: 1.6rem;
      font-weight: bold;
      height: 100%;
      display: flex;
      align-items: center;

      &:first-letter {
        text-transform: capitalize;
      }

      a,
      .parent-link {
        box-sizing: border-box;
        display: block;
        color: ${(props) => props.theme.primaryColor};
        padding: 16px;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        svg {
          padding-left: 8px;
        }
        svg path {
          fill: ${(props) => props.theme.primaryColor};
        }
        &.active {
          &:before {
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: 0;
            left: 0;
            content: "";
            background-color: ${(props) => props.theme.primaryColor};
          }
        }
      }

      & + li {
        margin-left: 1.6rem;
      }
      &.parent:hover {
        & > ul {
          display: flex;
          ${MEDIA.TABLET`
            display: flex;
          `}
        }
      }
      &.parent {
        ${MEDIA.TABLET`
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          position: relative;
          padding: 0;
        `}
      }
      ul.sub-menu {
        display: none;
        position: absolute;
        background: #fff;
        padding: 16px 32px;
        flex-direction: column;
        align-items: flex-start;
        z-index: 9999;
        top: 100%;
        right: 0;
        ${MEDIA.TABLET`
          position: relative;
          display: flex;
          padding: inherit;
          top: 0;
          padding-left: 48px;
          li a {
            left: 0;
          }
        `}

        li {
          margin: 0;
          a {
            padding: 8px;
            width: 100%;
          }
        }
      }
    }
  }
`;
