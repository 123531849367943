import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
`;

const ChevronDown = ({ height, width, fill }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 76 95"
    enable-background="new 0 0 76 76"
    height={height}
    width={width}
    xmlSpace="preserve"
    stroke={fill}
    fill={fill}
  >
    <path d="M37.938,51.251c-0.244,0-0.487-0.089-0.679-0.266l-26.5-24.5c-0.406-0.375-0.431-1.008-0.056-1.413  c0.375-0.407,1.007-0.431,1.413-0.056l25.823,23.875l25.947-23.875c0.405-0.373,1.039-0.348,1.413,0.059  c0.373,0.407,0.348,1.039-0.06,1.413l-26.625,24.5C38.423,51.163,38.18,51.251,37.938,51.251z" />
  </Svg>
);

export default ChevronDown;
