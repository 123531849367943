import { Link } from "gatsby";
import React from "react";

export default function Logo({ data, locale }) {
  const { logo, title } = data.site_settings;
  return (
    <div className="logo">
      <Link to={'/'}>
      {logo && logo[0] && (
        <img alt={title} className="logo-img" src={logo ? 'https://cdn.burobork.nl/' + logo[0].reference : ''} />
      )}
      {!logo && title}
      </Link>
    </div>
  )
}