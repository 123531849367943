import styled from "styled-components";

export const PageContainer = styled.div`
  h1 {
    font-size: 3.2rem !important;
    font-weight: 900;
  }

  h2 {
    font-weight: bold;
    padding: 0 0 8px;
  }

  h3,
  h4 {
    font-weight: 500;
    padding: 0 0 8px;
  }

  .bu_cpb {
    &_text {
      padding: 16px 0;
    }
    &_image_slider {
      width: 100%;
    }
  }
  .page_row {
     display: flex;
     flex-wrap: wrap;
  }

  #bukazu-app {
    a:before {
      display: none;
    }
  }

  .bu-pagination {
    li {
      padding: 8px;

    }
  }
`;
