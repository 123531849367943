import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RegionsContainer } from "./footer.css";

export default function Regions({ locale, default_locale }) {
  return (
    <StaticQuery
    query={graphql`
    query {
      allBelvillaRegion {
        edges {
          node {
            id
            name
            titles {
              nl
              de
              en
            }
            cms_id
          }
        }
      }
    }
  `}
  render={data => {
    return (
    <RegionsContainer>
      <h3>
        <FormattedMessage id="regions" />
      </h3>
      <ul>
        {data.allBelvillaRegion.edges.map(regio => {
          const slug = string_to_slug(regio.node.name);
          let path = '/'
          if (locale === default_locale) {
            path = '/regions/' + slug;
          } else {
            path = path + locale + '/regions/' + slug;
          }
          return (
          <li>
            <a href={path}>
              {regio.node.name}
            </a>
          </li>
        )})}
      </ul>
    </RegionsContainer>
  )}}
  >

    </StaticQuery>
  )
}

function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}
